(function($, window, document, undefined) {
  'use strict';

  var $slides = $('#slides');

  var initCarousel = function() {
    $slides.find('[class^=owl-]').each(function() {
      $(this).addClass(function() {
        return this.className.replace('owl', 'carousel');
      });
    });
  };

  $('.slide').each(function() {
    var
      $slide = $(this),
      background = $slide.css('background-image'),
      url = $slide.find('.slide-image').attr('src')
    ;
    $slide.css('background-image', 'url(' + url + '),' + background);
  });

  $slides.find('.carousel').owlCarousel({
		singleItem: true,
		pagination: true,
    navigation: false,
    autoPlay: true,
    slideSpeed: 1200,
    afterAction: initCarousel
	});

})(window.jQuery || window.Zepto, window, document);
