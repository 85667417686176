(function($, window, document, undefined) {
  'use strict';

  $('.shortcut[href="#nav"]').on('click', function(e) {
    var $viewport = $('html, body');
    var $html = $('html');
		e.preventDefault();
    $html.toggleClass('menu-active');
    if ($html.hasClass('menu-active')) {
      $viewport.css('overflow', 'hidden');
    } else {
      $viewport.css('overflow', 'visible');
    }
  });

})(window.jQuery || window.Zepto, window, document);
