'use strict';

(function($, window, document, undefined) {

  var
    dialog,
    $logo = $('#profile-logo'),
    $buttonAdd = $('[data-action="logo-add"]'),
    $buttonRemove = $('[data-action="logo-remove"]')
  ;

  if (! $('#logo-current').attr('src')) {
    $buttonRemove.hide();
  } else {
    $buttonAdd.hide();
  }

  $buttonAdd.on('click', function(e) {
    e.preventDefault();
    if (dialog) {
      dialog.open();
      return;
    }
    dialog = wp.media.frames.dialog = wp.media({
      title: $(this).data('uploader_title'),
      frame: 'select',
      multiple : true,
      library: {
        type : 'image',
        uploadedTo: wp.media.view.settings.post.id
      },
      button: {
        text: $(this).data('uploader_button_text'),
      }
    });

    // dialog.on('close',function() {
    //   var selection = dialog.state().get('selection');
    //   var galleryIds = [];
    //   var index = 0;
    //   selection.each(function(attachment) {
    //     galleryIds[index] = attachment['id'];
    //     index++;
    //   });
    //   var ids = galleryIds.join(',');
    //   $('#gallery-ids').val(ids);
    //   Refresh_Gallery(ids);
    // });
    //
    // dialog.on('open',function() {
    //   var selection = dialog.state().get('selection');
    //   ids = $('#gallery-ids').val().split(',');
    //   ids.forEach(function(id) {
    //     attachment = wp.media.attachment(id);
    //     attachment.fetch();
    //     selection.add(attachment ? [ attachment ] : []);
    //   });
    // });

    dialog.on('select', function() {
      attachment = dialog.state().get('selection').first().toJSON();
      if (attachment.id) {
        $('#logo-new').fadeOut(300, function() {
          $(this).attr('src', attachment.url).fadeIn();
          $('#logo-current').attr('src', '').fadeOut();
          $buttonAdd.fadeOut();
        })
        $logo.val(attachment.id);
      }
    });
    dialog.open();
  });

  $buttonRemove.on('click', function(e) {
    e.preventDefault();
    if (confirm('Sind Sie sicher?')) {
      $logo.val('');
      $('#logo-current').attr('src', '').fadeOut();
      $(this).fadeOut();
      $buttonAdd.fadeIn();
    }
  });

})(window.jQuery || window.Zepto, window, document);
