(function($, window, document, undefined) {
  'use strict';

  $('.teaser-item').each(function() {
    var $teaser = $(this);
    var url = $teaser.find('.teaser-link').attr('href');
    var image = $teaser.find('.background-image').attr('src');
    this.onclick = function() {
      location.href = url;
    };
    $teaser.find('.teaser-image').css('background-image', 'url(' + image + ')');
  });

})(window.jQuery || window.Zepto, window, document);
