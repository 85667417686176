(function($, window, document, undefined) {
  'use strict';

  var cookieData = Cookies.get('MV_TEXT_SIZE') || 'normal';

  var setTextSize = function(textSize) {
    if ('undefined' === typeof textSize) {
      return false;
    }
    $('html')
    .removeClass('text-size-normal text-size-large text-size-x-large')
    .addClass('text-size-' + textSize);
    Cookies.set('MV_TEXT_SIZE', textSize, { path: '/', expires: 365 });
    return true;
  }

  var handleEvent = function($element) {
    $('.resizer-item').removeClass('active');
    $element.parent().addClass('active');
    return setTextSize($element.data('text-size'));
  }

  $(document).ready(function() {
//    $.get('resizer.html', function(data) {
    $.get('http://www.xn--unternehmen-fr-meckenheim-rwc.de/wp-content/themes/meckenheimer-verbund/partials/resizer.php', function(data) {
      $(data).insertBefore('#main');
      if (cookieData.length) {
        var $element = $('[data-text-size="' + cookieData + '"]');
        return handleEvent($element);
      }
    });
  });

  $(document.body).on('click', '.resizer-item-button', function() {
    return handleEvent($(this));
 });

})(window.jQuery || window.Zepto, window, document);
