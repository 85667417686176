(function($, window, document, undefined) {
  'use strict';

  $('.sector-link').on('click', function(e) {
    e.preventDefault();
    var $sectorItem = $(this).closest('.sector-item');
    $('.sector-item').not($sectorItem).removeClass('active');
    $sectorItem.toggleClass('active');
  });

  $('#members-filter-sector').on('change', function(e) {
    window.location = $(this).val();
  });

})(window.jQuery || window.Zepto, window, document);
